.wrapper {
  display: block;
  text-align: center;
  width: 100%;
}

.wrapper > img {
  width: 50vw;
  margin: auto;
  border-radius: 5%;
}

.info {
  padding: var(--mb-2);
  font-size: var(--mb-3);
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 0;
  }
  .wrapper > img {
    width: 90vw;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 1vw rgba(255, 255, 255, 1);
  }
  25% {
    box-shadow: 0 0 2vw rgba(255, 255, 255, 0.9);
  }
  50% {
    box-shadow: 0 0 6vw rgba(255, 255, 255, 0.7);
  }
  75% {
    box-shadow: 0 0 10vw rgba(255, 255, 255, 0.4);
  }
  100% {
    box-shadow: 0 0 15vw rgba(255, 255, 255, 0);
  }
}

.pulse {
  animation: pulse 3s infinite ease-in-out;
}
