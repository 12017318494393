.hero {
   /* background-image: url('../../../public/hero-bg.png'); */
   background-size: cover;
   height: 100vh;
   padding: 3rem 2rem;
   text-align: center;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.heroTextContainer {
   margin-bottom: 8rem;
}

.heroText {
   color: whitesmoke;
   font-size: 5rem;
   text-transform: uppercase;
   margin-bottom: 2rem;
}

.heroSubtext {
   color: whitesmoke;
   font-size: 3rem;
   margin-bottom: 3rem;
}

.peaks {
   width: 100%;
   position: absolute;
   bottom: 0;
}

@media screen and (max-width: 1170px) {
   .heroTextContainer {
      margin-bottom: 0rem;
   }
}


.animated-button {
   transition: all 0.2s ease-in-out;
 }
 
 .animated-button.hovered {
   transform: scale(1.1);
 }

 .navButton {
   background: rgba(255, 255, 255, 0.35);
   border-radius: 0.5rem;
   padding: 0.5rem 1.4rem;
   border: none;
   font-weight: 600;
   font-size: 1.2rem;
   font-family: var(--primary);
   color: var(--primary);
   text-transform: uppercase;
   border: 2px solid var(--primary);
   cursor: pointer;
 }
 
 .navButton_mobile {
   background: rgba(255, 255, 255, 0.35);
   border-radius: 0.5rem;
   padding: 0.5rem 1.4rem;
   border: none;
   font-weight: 600;
   font-size: 0.8rem;
   font-family: var(--primary);
   color: var(--primary);
   text-transform: uppercase;
   border: 2px solid var(--primary);
   cursor: pointer;
 }
 