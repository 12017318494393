.container {
  min-width: 100vw;
  min-height: 100vh;
  /* background-color: rgb(50, 252, 97); */
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  padding-inline: 3rem;
}
#email,
#number,
#msg {
  width: 100%;
}
.text {
  margin: 2rem;
  width: 80%;
  flex: 1 1 60%;
  padding: 1.5rem;
  position: relative;
  font-size: var(--mb-3);
}
.contactDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.textTitle {
  font-size: 2rem;
  font-weight: bolder;
  margin-top: 30px;
}
.textName {
  margin-top: 20px;
  margin-inline: 1rem;
}
.textNumber {
  margin-bottom: 20px;
  margin-inline: 1rem;
}
.social_menu_ul {
  position: relative;
  top: 60%;
  padding: 0;
  margin: 0;
  /* transform: translate(-50%, -50%); */
  display: flex;
  align-items: center;
  justify-content: center;
}
.social_menu_ul_li {
  list-style: none;
  margin: 20px 15px;
}
.fab:hover {
  color: #fff;
}
.social_menu_ul_li_a {
  position: relative;
  display: flex;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  transition: 0.6s;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.5);
  padding: 10px;
  background-color: var(--text);
}

.social_menu_ul_li_a:hover {
  transform: scale(1.3);
  transition: 0.4s;
}
.social_menu_ul_li:nth-child(1) a:hover {
  background-color: #e4405f;
}
.social_menu_ul_li:nth-child(2) a:hover {
  background-color: #3b5998;
}
.social_menu_ul_li:nth-child(3) a:hover {
  background-color: #00acee;
}
.social_menu_ul_li:nth-child(4) a:hover {
  background-color: #0e76a8;
}
.social_menu_ul_li:nth-child(5) a:hover {
  background-color: #0f6cbd;
}
.label {
  color: rgb(0, 0, 0);
  margin-top: 1em;
}

.form_outline {
  height: 95%;
  background: linear-gradient(
    to right bottom,
    rgba(163, 232, 245, 0.7),
    rgba(23, 49, 107, 0.844)
  );
  padding: 2rem;
  border-radius: 0.65rem;
  box-shadow: 1px 2px 10px rgb(95, 175, 255);
  /* margin-top: 2.5rem;
    margin-bottom:1rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  flex: 1 1 40%;
  margin-top: 1rem;
  padding-top: 3rem;
}

.input {
  outline: none;
  border: none;
  height: 30px;
  border-radius: 0.65rem;
  width: 260px;
  margin: 5px;
  padding: 1rem;
}
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input[type="number"] {
  appearance: textfield;
}
.label {
  font-weight: 700;
  display: inline-block;
  width: 100%;
  text-align: left;
  margin-left: 15px;
}
.textarea {
  outline: none;
  margin: 5px 5px 20px 5px;
  padding: var(--mb-1);
  border-radius: 0.65rem;
  border: none;
  width: 260px;
  height: 100%;
}
::placeholder {
  color: rgb(121, 120, 120);
  text-align: center;
}

#btn__send {
  background: var(--primary);
  color: var(--white);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.6s;
  font-size: 16px;
  font-weight: 700;
  height: 40px;
  border: none;
  border-radius: 0.65rem;
  letter-spacing: 1px;
  margin-top: 10px;
}
.bg,
.button {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.downBtncontainer {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-top: 60px;
  left: 40px;
  bottom: 10px;
}
.bg,
.downButton {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 100%;
}
.bg {
  animation: pulse 1.2s ease infinite;
  background: var(--text);
}

.downButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99;
  border: none;
  background: var(--primary);
  background-size: 18px;
  cursor: pointer;
  outline: none;
}

.downButton a {
  position: absolute;
  color: #fff;
  font-size: 17px;
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
#btn__send:hover {
  cursor: pointer;
  transition: 0.5s;
  transform: scale(1.1);
  letter-spacing: 4px;
  color: var(--primary);
  background-color: rgb(255, 255, 255);
}
.go__home__btn {
  margin-top: 7em;
  cursor: pointer;
}
.fab:hover {
  color: rgb(255, 255, 255);
}
.CTFLink {
  padding: 2rem;
  display: flex;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  transition: 0.6s;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.5);
  padding: 10px;
  background-color: var(--text);
  margin-top: 1rem;
  margin-inline: auto;
}
.CTFLink:hover {
  transform: scale(1.3);
  transition: 0.4s;
}

@media screen and (max-width: 1170px) {
  .container {
    flex-direction: column;
    padding-top: 5rem;
    padding-inline: 0px;
  }
  .social_menu ul li {
    margin: 20px 10px;
  }
  .downBtncontainer {
    display: flex;
  }
}
@media screen and (max-width: 1170px) {
  .text {
    width: 95%;
    margin-inline: 10px;
    padding: 1rem;
    margin-bottom: 10px;
  }
  .form__container {
    margin-top: 0px;
  }
  .label {
    font-size: 0.95rem;
  }

  .form_outline {
    /* margin-left: -3.3rem; */
    font-size: 10px;
    margin-top: 0px;
  }

  .social_menu_ul_li .fab {
    font-size: 25px;
    line-height: 50px;
  }
  .social_menu_ul_li_a,
  .CTFLink {
    width: 50px;
    height: 50px;
  }
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .text{
    font-size: var(--mb-2);
  }

  .textTitle{
    margin-top: 0px;
  }
}