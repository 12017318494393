.footer {
   background-color: rgb(0, 0, 0);
   /* background-color: antiquewhite; */
   display: flex;
   justify-content: space-between;
   padding: 2rem;
   z-index: 10;
}

.footerLeft {
   flex: 2.2;
   padding: 20px;
   display: flex;
   align-items: center;
   z-index: 9;
}

.footerRight {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   flex: 1;
   padding: 20px;
   z-index: 9;
}

.logo {
   height: 5rem;
   width: 20rem;
   padding: 10px;
   z-index: 9;
}

.about {
   text-align: left;
   padding: 15px;
   z-index: 9;
}

.iconContainer {
   display: flex;
   padding-bottom: 0.5rem;
   align-items: center;
   justify-content: center;
   z-index: 9;
}

h2 {
   padding-bottom: 1rem;
   z-index: 9;
}

.icon {
   padding-right: 8px;
   z-index: 9;
}

@media screen and (max-width: 1023px) {
   .footer {
      flex-direction: column;
      z-index: 9;
   }
   .footerLeft {
      flex-direction: column;
      z-index: 9;
   }

   .logo {
      height: 5rem;
      width: 12rem;
      padding: 10px;
      z-index: 9;
   }
}

@media screen and (max-width: 768px) {
   .logo {
      width: 8rem;
   }
}
