.xceedTypeTitle {
  text-align: center;
  text-transform: uppercase;
  font-size: 5rem;
  padding-top: 20vh;
}

.xceedTypeDescription {
  font-size: var(--mb-2);
  text-align: justify;
  color: #ffffff;
  margin: auto;
  width: 70%;
  font-weight: 100;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(8px);
  padding-bottom: 3%;
}

.xceedAllYearsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.xceedTypeYearWiseContainer {
  width: 80%;
  margin: 20px auto;
  display: flex;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 4px 72px 15px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  transition: all 0.5s ease-in-out;
}
.xceedTypeYearWiseContainer:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.xceedYearImage {
  width: 300px;
  margin: auto;
}

.xceedYearContents {
  width: 70%;
  margin: 30px auto;
}

.xceedTypeYearWiseImage {
  width: 200px;
  height: 200px;
  margin: 40px auto;
  border-radius: 10%;
  /* margin: 20px auto; */
}

.xceedTypeYearWiseYear {
  text-align: center;
}

.xceedTypeYearWisePlace {
  text-align: center;
  text-transform: capitalize;
  margin-top: 20px;
}

.xceedTypeYearWiseContents {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: auto;
  /* padding: 20px auto; */
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1025px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .xceedTypeTitle {
    font-size: 3rem;
  }
  .xceedTypeYearWiseContainer {
    display: flex;
    flex-direction: column;
  }
  .xceedYearContents {
    margin-top: 0px;
    padding: 0px;
  }
}
