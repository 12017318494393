.xceedInfoHeader {
  font-size: 6rem;
  color: rgb(217, 217, 217);
  text-shadow: 12px 20px 10px rgba(47, 44, 44, 0.25);
  padding-top: 20vh;
  padding-bottom: var(--mb-2);
}

.xceedInfoDescription {
  font-size: var(--mb-3);
  text-align: justify;
  color: #ffffff;
  margin: auto;
  width: 70%;
  font-weight: 100;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(8px);
}

.xceedTypeContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.xceedTypeCard {
  /* width: 400px; */
  height: 400px;
  margin: 3%;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(2px);
  text-decoration: none;
  color: var(--text);
  transition: all 0.5s ease-in-out;
}
.xceedTypeCard:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.xceedTypeTitle {
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--text);
}

.xceedTypeDescription {
  text-align: center;
  margin: auto;
  padding: 20px;
  text-decoration: none;
  color: var(--text);
}

.xceedTypeYearWiseImage {
  display: block;
  width: 100%;
  max-height: 250px;
  object-fit: cover;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 20px auto;
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .xceedTypeContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-bottom: 4rem;
  }
  .xceedInfoDescription {
    width: 90%;
    font-size: var(--mb-2);
  }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
}
