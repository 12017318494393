
.title {
  font-size: 5rem;
  color: rgb(217, 217, 217);
  text-shadow: 12px 20px 10px rgba(47, 44, 44, 0.25);
  padding-top: 20vh;
  /* padding-bottom: 4%; */
}
.desc {
  font-size: var(--mb-3);
  text-align: justify;
  color: #ffffff;
  margin: auto;
  width: 70%;
  font-weight: 100;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(8px);
}
iframe {
  width: 650px;
  height: 1852px;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  iframe {
    width: 370px;
    height: 2152px;
  }
}
.content {
  z-index: 9;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}
/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .desc {
    width: 80%;
    font-size: var(--mb-2);
  }
}

/* Mobile only */
@media only screen and (max-width: 767px) {
}


