.ocean_bg {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: linear-gradient(0deg, #022244, #0051c3dc);
    margin: 0;
    margin-top: 4.5rem;
    position: relative;
}

.ocean {
    position: relative;
    width: 100vw;
    height: 100vh;
}

.ocean::after,
.ocean::before {
    background-color: rgba(255, 255, 255, 0.668);
    width: 120%;
    height: 120px;
    position: absolute;
    content: "";
    top: -80px;
    left: -10%;
    border-radius: 100%;
    animation: waves 10s ease infinite;
    opacity: 0.2;
}

.ocean::before {
    top: -60px;
    animation-delay: 5s;
    opacity: 0.1;
    left: -5%;
}

@keyframes waves {
    0%, 100% {
        transform: rotate(-2deg);
    }

    50% {
        transform: rotate(2deg);
    }
}

.bubbles {
    width: 100%;
    height: 100vh;
    animation: bubbles 10s linear infinite;
    z-index: 0;
}

@keyframes bubbles {
    0%, 100% {
        transform: rotate(-5deg);
    }

    50% {
        transform: rotate(5deg);
    }
}

.bubbles span {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.866);
    bottom: -10%;
    animation: bubble 10s ease-in-out infinite;
}

@keyframes bubble {
    0% {
        bottom: -10%;
        opacity: 0;
    }

    100% {
        bottom: 100%;
    }
}

.bubbles span:nth-child(1) {
    width: 50px;
    height: 50px;
    opacity: 0.5;
    left: 10%;
    animation-delay: 3s;
    animation-duration: 10s;
    opacity: 0.2;
}

.bubbles span:nth-child(2) {
    width: 30px;
    height: 30px;
    opacity: 0.5;
    left: 40%;
    animation-delay: 1s;
    animation-duration: 5s;
    opacity: 0.1;
}

.bubbles span:nth-child(3) {
    width: 10px;
    height: 10px;
    opacity: 0.5;
    left: 30%;
    animation-delay: 5s;
    animation-duration: 20s;
    opacity: 0.3;
}

.bubbles span:nth-child(4) {
    width: 35px;
    height: 35px;
    opacity: 0.5;
    left: 40%;
    animation-delay: 8s;
    animation-duration: 8s;
    opacity: 0.2;
}

.bubbles span:nth-child(5) {
    width: 45px;
    height: 45px;
    opacity: 0.5;
    left: 60%;
    animation-delay: 10s;
    animation-duration: 15s;
    opacity: 0.1;
}

.bubbles span:nth-child(6) {
    width: 40px;
    height: 40px;
    opacity: 0.5;
    left: 80%;
    animation-delay: 3s;
    animation-duration: 30s;
    opacity: 0.4;
}

.bubbles span:nth-child(7) {
    width: 15px;
    height: 15px;
    opacity: 0.5;
    left: 90%;
    animation-duration: 25s;
    opacity: 0.3;
}

.bubbles span:nth-child(8) {
    width: 20px;
    height: 20px;
    opacity: 0.5;
    left: 50%;
    animation-duration: 10s;
    opacity: 0.2;
}

.bubbles span:nth-child(9) {
    width: 20px;
    height: 20px;
    opacity: 0.5;
    left: 70%;
    animation-duration: 4s;
    opacity: 0.2;
}

.bubbles span:nth-child(10) {
    width: 24px;
    height: 24px;
    opacity: 0.5;
    left: 10%;
    animation-duration: 11s;
    opacity: 0.2;
}

.bubbles span:nth-child(11) {
    width: 20px;
    height: 20px;
    opacity: 0.5;
    left: 40%;
    animation-duration: 12s;
    opacity: 0.2;
}

.fish {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    background-color: rgba(0, 102, 255, 0.804);
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    box-shadow: inset 0px -6px 0px rgba(0, 0, 0, 0.1);
    animation: fish 40s ease-in infinite;
}

@keyframes fish {
    0% {
        left: calc(100% + 30px);
        top: 60%;
    }

    50% {
        top: 40%;
    }

    100% {
        left: -20%;
        top: 50%;
    }
}

/* fish tail */
.fish::after,
.fish::before {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background-color: inherit;
    content: "";
    position: absolute;
    top: 30%;
    right: -20px;
    transform: rotate(-20deg);
    z-index: -1;
    animation: tail 2s ease infinite;
}

.fish::before {
    top: 42%;
    transform: rotate(20deg);
}

@keyframes tail {
    0%, 100% {
        right: -20px;
    }

    50% {
        right: -10px;
    }
}

/* rest of the fish */
.fish span {
    content: "";
    position: absolute;
}

/* eyes - white part */
.fish span:nth-child(1),
.fish span:nth-child(2) {
    border-radius: 100%;
    width: 30px;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.899);
    box-shadow: inset 0px -5px 0px rgba(0, 0, 0, 0.1);
    top: 20px;
    z-index: -2;
}

.fish span:nth-child(2) {
    z-index: 0;
    left: 35px;
    top: 25px;
}

/* eyes - black part */
.fish span:nth-child(1)::after,
.fish span:nth-child(2)::after {
    border-radius: 100%;
    width: 10px;
    height: 10px;
    background-color: #2a2a2a;
    content: "";
    position: absolute;
    z-index: 1;
    top: 6px;
    left: 6px;
    box-shadow: inset 0px 2px 0px rgba(255, 255, 255, 0.5);
    animation: eyes 4s ease infinite;
}

@keyframes eyes {
    0%, 100% {
        top: 4px;
    }

    50% {
        top: 10px;
    }
}

/* mouth */
.fish span:nth-child(3) {
    border-radius: 100%;
    width: 25px;
    height: 15px;
    background-color: rgb(159, 62, 62);
    box-shadow: inset 0px -5px 0px rgba(0, 0, 0, 0.3);
    top: 50px;
    left: -5px;
    z-index: 1;
    transform: rotate(5deg);
    border: solid 5px rgb(0, 89, 255);
    animation: mouth 4s ease infinite;
}

@keyframes mouth {
    0%, 100% {
        height: 25px;
        width: 15px;
    }

    50% {
        height: 15px;
        width: 8px;
    }
}

/* fish bubbles */
.fish span:nth-child(4),
.fish span:nth-child(5),
.fish span:nth-child(6) {
    border-radius: 100%;
    width: 30px;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.735);
    box-shadow: inset 0px 5px 0px rgba(0, 0, 0, 0.3);
    top: 20px;
    left: -30px;
    z-index: -2;
    opacity: 0.3;
    animation: fishbubbles 4s ease infinite;
    z-index: 2;
}

.fish span:nth-child(5) {
    animation-delay: 0.5s;
    width: 20px;
    height: 20px;
}

.fish span:nth-child(6) {
    animation-delay: 1s;
    width: 10px;
    height: 10px;
}

@keyframes fishbubbles {
    0% {
        width: 0;
        height: 0;
        top: 70px;
        left: 2px;
    }

    50% {
        left: -20px;
    }

    100% {
        top: -80px;
        opacity: 0;
    }
}