.App {
  text-align: center;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  /* background: linear-gradient(112.98deg, #661185 16.98%, #0CBABA 94.59%); */
}

.seabed {
  position: absolute;
  bottom: 0;
  opacity: 0.55;
  width: 100%;
}

.bg {
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  /* z-index usage is up to you.. although there is no need of using it because the default stack context will work. */
  z-index: -1;
}

.PhoneInputInput {
  border: none;
  background: none;
  
  outline: none;
  font-size: 1rem;
  color: var(--white);
}

.toastBody a {
  text-decoration: none;
  color: var(--text);
}