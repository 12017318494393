@media screen and (max-width: 1024px) {
    .container .myswiper {
      width: 85vw;
      aspect-ratio: 2;
    }
    .container .myswiper .swiperslide {
      object-fit: fill;
    }
  }
  @media screen and (min-width: 1024px) {
    .container .myswiper {
      width: 85vw;
      max-width: 1000px;
      aspect-ratio: 2;
    }
    .container .myswiper .swiperslide {
      object-fit: fill;
    }
  }
  