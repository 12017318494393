.wrapper_404	{
	display: flex;
	flex-direction: column;
	width: 100%;
	text-align: center;
	margin: 10% 0;
}
.img_404	{
	margin: auto;
}
@media only screen and (max-width: 767px) {
	.wrapper_404	{
		margin: 40% 0;
	}
}